import React, { useState } from "react"
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from "gatsby"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import GoogleImage from "../GoogleImage"
import HoverButton from "../buttons/HoverButton"
import { devices } from "../../settings"

const Section = styled.div`
    padding: 25px 10px;
    background-color: white;

    > h2 {
        font-size: 25px;
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
        margin: 0px 0px 20px 0px;
    }
`
const Posts = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
`
const Post = styled.li`
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 5px 5px #ccc;
  margin-bottom: 40px;
  display: flex;

  &:hover {
    border-color: #999;
    box-shadow: 0px 10px 10px #999;
  }

  @media ${devices.mobile} {
    display: block;
  }  

  > div.preview {
    min-width: 250px;
    flex: 0;

    img {
      border-radius: 5px;
      width: 100%;
      height: auto;

      @media ${devices.mobile} {
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }
  }
  > div {
    flex: 1;
    padding: 10px;

    h3 {
      margin: 0px;
      padding: 0px;
      margin-bottom: 5px;
    }
    small {
      display: block;
      color: #999;
      margin-bottom: 10px;
    }
    p {
      margin-top: 0px;
    }
    
  }
`

function BlogSection(props) {
    const data = useStaticQuery(graphql`
        {
            allMysqlBlogPosts(limit: 3, sort: {fields: added_date, order: DESC}) {
                edges {
                    post: node {
                        id
                        name
                        title
                        added_date_formatted
                        preview_url
                        description                    
                    }
                }
            }
        }
    `);

    const [posts, setPosts] = useState(data.allMysqlBlogPosts.edges)

    return (
        <Section>
            <h2>Recent Blog Posts</h2>
            <Posts>
                {posts.map(({post}) => (
                <Post key={post.id}>
                    <div className="preview">
                      <Link to={"/blog/" + post.name + "/"}>
                        <GoogleImage root src={post.preview_url} required lazy width={230} height={127} />
                      </Link>
                    </div>
                    <div>
                        <Link to={"/blog/" + post.name + "/"}>
                        <h3>{post.title}</h3>
                        </Link>
                        <small>{post.added_date_formatted}</small>
                        <p>{post.description}</p>

                        <Link to={"/blog/" + post.name + "/"}>
                          <HoverButton                       
                            label="Read More" 
                            size="small"
                            icon={faChevronRight}
                            iconPos="right"
                          />
                        </Link>                        
                    </div>
                </Post>
                ))}
            </Posts>
            <Link to="/blog/">
                <HoverButton
                    label="More Posts"
                    icon={faChevronRight}
                    color="blue"
                />
            </Link>
        </Section>
    );
}

export default BlogSection